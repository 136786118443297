.BadgeCrearPost {
    background: #FFF;
    overflow: hidden;

    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    padding: 16px;
    margin: 0 auto 0;
  }

  
  .btn.btn-seleccionar-foto {
    width: auto;
    height: 20vh;
    font-size: large;
  
    color: #31313190;
    background-color: transparent;
    border-color: transparent;
  
    margin: 10vh 20px;
  }
  
  .btn.btn-seleccionar-foto:hover {
    color: #31313190;
    background-color: #ffffff20;
  }

  .btn.btn-mostrar-foto {
    width: min-content;
    height: 20vh;
    font-size: large;
  
    color: #31313190;
    background-color: #ffffff20;
    border-color: transparent;
  
    margin: 10vh 20px;
  }
  
  .btn.btn-mostrar-foto:hover {
    color: #31313190;
    background-color: transparent;
  }

  
  /* Texto */
  .BadgeCrearPost-texto {
    width: 90%;
    height: 70vh;

    margin: 16px 5% 8px;
    padding: 8px;

    border: #31313110;
    border-style: solid;
    border-radius: 16px;
    border-width: 2px;
    
    resize: vertical;
  }
  .BadgeCrearPost-texto:focus {
    border: #31313120;
  }

  /* Opciones (barra de funciones) */
  .BadgeCrearPost-opciones {
    width: 90%;
    height: auto;

    margin-top: 0;
    margin-inline: 5%;
    margin-bottom: 16px;

    display: flex;
    justify-content: space-between;
  }
 
  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

    /* Texto */
    .BadgeCrearPost-texto {
      width: 100%;
      height: 50vh;
      margin: 16px 0 8px;
    }

    /* Opciones (barra de funciones) */
    .BadgeCrearPost-opciones {
      width: 100%;
      height: auto;

      margin-inline: 0;

      display: block;
    }


  }