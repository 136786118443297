@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700');

:root {

  /*---------- Tema de día ----------*/
  --light-background: #fff;
  --light-background-portada: #f9fafc;
  --light-filter-portada: drop-shadow(0px 1px 1px #31313120);
  --light-divider: #2f2f2f30;

  /* Textos */
  --light-texto: #000;

  /* Botones */
  --light-icon: 0%;
  --light-icon-hover: 100%;

  /*---------- Tema de noche ----------*/
  --dark-background: #191919;
  --dark-background-portada: #181819;
  --dark-filter-portada: drop-shadow(0px 1px 1px #eaeaea20);
  --dark-divider: #fcfcfc30;

  /* Textos */
  --dark-texto: #fff;

  --dark-icon: 100%;
  --dark-icon-hover: 0%;
}

/* ------------------------------ TEMA LIGHT --------------------------- */
[theme="light"] {
  
  /* Padre */
  --background: var(--light-background);
  --background-portada: var(--light-background-portada);
  --filter-portada: var(--light-filter-portada);
  --divider-component: var(--light-divider);

  /* Textos */
  --texto: var(--light-texto);

  /* Botones */
  --icon: var(--light-icon);
  --icon-hover: var(--light-icon-hover);
}

/* ------------------------------ TEMA DARK ---------------------------- */
[theme="dark"] {
  
  /* Padre */
  --background: var(--dark-background);
  --background-portada: var(--dark-background-portada);
  --filter-portada: var(--dark-filter-portada);
  --divider-component: var(--dark-divider);

  /* Textos */
  --texto: var(--dark-texto);

  /* Botones */
  --icon: var(--dark-icon);
  --icon-hover: var(--dark-icon-hover);
}

/* ------------------------------ */

.BadgeLeerEntrada {
  
    overflow: hidden;

    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    padding: 0 0%;
    margin: 0 auto 0;

    background: var(--background);
  }

  /* Imagen de portada */  
  .BadgeLeerEntrada-imagen-portada {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 35/9;
    
    -moz-opacity: 0.20;
    opacity: 0.20;
    filter:alpha(opacity=20);
  }


  /* Título */
  .BadgeLeerEntrada-titulo {
    width: 100%;
    padding: 1rem 0.5rem;

    font-size: 300%;
    line-height: 50px;
    font-weight: 700;
    letter-spacing: 0px;
      
    font-family: 'Montserrat';
    color: #45484e;
  }

  /* Subtítulo */
  .BadgeLeerEntrada-subtitulo {
    width: 100%;
    padding: 0 0.5rem;

    font-size: 160%;
    line-height: 45px;
    font-weight: 600;
    letter-spacing: 0px;

    font-family: 'Montserrat';
    color: 	#7289da;
  }

  /* Hora y Fecha */
  .BadgeLeerEntrada-fecha {
    line-height: 25px;
    letter-spacing: 0px;
    font-family: 'Source Sans 3';
    font-size: medium;

    width: 100%;
    height: 100%;
    padding: 25px 7% 0;
    color: #536471;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Categoría */
  .BadgeLeerEntrada-categoria {
    width: -moz-fit-content;
    width: fit-content;

    display: flex; 
    align-self: flex-start;

    margin: 2rem 0 0;
    padding: 4px 8px;
    color: #333333;
    /* background: #e7e7e750; */
    background: white;
    border-radius: 16px;

    font-weight: bold;
    font-size: medium;
    font-family: 'Nunito';

    cursor: pointer;
  }

  .BadgeLeerEntrada-categoria:hover {
    background: #e7e7e7;
  }
  
  /* Texto */
  .BadgeLeerEntrada-texto {
    font-family: 'Nunito';
    font-weight: 500;
    width: 68%;
    height: auto;

    margin: 20px auto 250px;
    padding: 0;
    
    display: block;
    white-space: pre-wrap;

    color: var(--texto);
  }

  /* Firma del Autor -- CONTENEDOR PADRE */
  .BadgeLeerEntrada-firma {
    width: 68%;
    height: auto;
    
    display: flex;
    margin: 35px auto 0;
  }

  /* Firma del Autor -- avatar */
  .BadgeLeerEntrada-firma-avatar {
    -o-object-fit: cover;
    object-fit: cover;

    height: 52px;
    width: 52px;
    border-radius: 50%;
    
    margin-right: 10px;
  }

  /* Firma del Autor -- nombre */
  .BadgeLeerEntrada-firma-nombre {
    font-family: 'Montserrat';
    font-size: 19px;
    font-weight: bolder;

    color: #0F1419;
  }

  /* Firma del Autor -- nombre */
  .BadgeLeerEntrada-firma-usuario {
    display: block;
    font-family: 'Nunito';
    font-size: 17px;
    font-weight: 500;

    margin-top: -7px;

    color: #536471;
  }

  /* Firma del Autor -- verificado */
  .BadgeLeerEntrada-firma-verificado {
    height: 20px;
    width: 20px;

    margin-left: 5px;
  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 900px){

    /* Imagen de portada */
    .BadgeLeerEntrada-portada {
      height: 50vh;
    }

    /* Hora y Fecha */
    .BadgeLeerEntrada-fecha {
      font-size: 15px;
    }

    /* Categoría */
    .BadgeLeerEntrada-categoria {
      display: flex;
      justify-content: center;
      align-content: center;
      
      align-self: flex-start;

      margin: 2rem 0 0;
      padding: 4px 8px;

      border-radius: 16px;

      font-weight: bold;
      font-size: small;
      font-family: 'Nunito';
    }

    /* Texto */
    .BadgeLeerEntrada-texto {
      width: 86%;
      
      margin: 20px auto 100px;
      padding: 0;
      display: block;
    }

    /* Firma del Autor */
    .BadgeLeerEntrada-firma {
      width: 86%;
    }

    /* Firma del Autor -- avatar */
    .BadgeLeerEntrada-firma-avatar {
      height: 48px;
      width: 48px;

      margin-right: 8px;
    }

    /* Firma del Autor -- nombre */
    .BadgeLeerEntrada-firma-nombre {
      font-size: 17px;
    }

    /* Firma del Autor -- verificado */
    .BadgeLeerEntrada-firma-verificado {
      height: 19px;
      width: 19px;

      margin-left: 4px;
    }

  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    /* Categoría */
    .BadgeLeerEntrada-categoria {
      margin: 1rem 0 0;
    }
  }