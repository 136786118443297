@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');

/* Caja padre */
.textarea-contenedor {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;

  justify-content: center;
  
  align-items: flex-start;

  place-items: flex-start;

  margin: -4px 0;
}

/* TextArea para TextoPlano en Blog/Páginas */
.textarea-texto {
  width: 100%;
  min-height: 36px;
  /* height: auto; */

  margin: 4px 0;
  padding: 4px 8px;

  overflow: hidden;
  resize: none;

  border: transparent;
  border-style: solid;
  border-radius: 4px;
  border-width: 0.5px;

  /* color: #4a4b4b; */
  color: black;
  transition: 0.3s;
  opacity: 1;

  /* resize: none; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.textarea-texto:focus {
  border: transparent;
  opacity: 1;
}
.textarea-texto:hover {
  /* background: #f1f2f590; */
  background: transparent;
  opacity: 0.8;
}

/* Aquí van los estilos para tabletas grandes */
@media screen and (max-width: 991px){}

/* Aquí van los estilos para tabletas medianas */
@media screen and (max-width: 767px){}

/* Aquí van los estilos para smartphones */
@media screen and (max-width: 479px){}