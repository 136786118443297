.BadgeCrearPagina {
    background: #FFF;
    overflow: hidden;


    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    padding: 16px;
    margin: 0 auto 0;
  }

  /* Configuración global */
  /* textarea{
    border: none;
    padding: 5px 5px;
  }
  textarea:focus {
    border-radius: 5px;
    border-width: 2px;
    outline-color: #31313120;
  } */

  /* Imagen de portada */
  .BadgeCrearPagina-portada {
    height: 40vh;
    background: #f9fafc;
    filter: drop-shadow(0px 1px 1px #31313120);
  }
  
  .BadgeCrearPagina-imagen-portada {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 25/4;
    
    opacity: 0.20;
    -moz-opacity: 0.20;
    filter:alpha(opacity=20);
  }

  /* Contenido de portada */  
  .BadgeCrearPagina-header-contenido-portada {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
    
    margin: -40vh auto 0;
    padding: 0 5%;
  
    filter: contrast(100%);
  }

  .btn.btn-seleccionar-foto {
    width: auto;
    height: 20vh;
    font-size: large;
  
    color: #31313190;
    background-color: transparent;
    border-color: transparent;
  
    margin: 10vh 20px;
  }
  
  .btn.btn-seleccionar-foto:hover {
    color: #31313190;
    background-color: #ffffff20;
  }

  .btn.btn-mostrar-foto {
    width: min-content;
    height: 20vh;
    font-size: large;
  
    color: #31313190;
    background-color: #ffffff20;
    border-color: transparent;
  
    margin: 10vh 20px;
  }
  
  .btn.btn-mostrar-foto:hover {
    color: #31313190;
    background-color: transparent;
  }

  /* Título */
  .BadgeCrearPagina-titulo {
    width: 100%;
    height: auto;
    resize: none;
    margin-top: 20px;

    font-size: 32px;
    font-family: 'Montserrat';
    font-weight: bolder;
  }

  /* Subtítulo */
  .BadgeCrearPagina-subtitulo {
    width: 100%;
    height: auto;
    resize: none;

    font-size: 20px;
    font-family: 'Nunito';
    font-weight: bold;
  }
  
  /* Texto */
  .BadgeCrearPagina-texto {
    width: 100%;
    height: 70vh;
  }
 
  /* Aquí van los estilos para smartphones */
@media screen and (max-width: 479px){

  /* Contenido de portada */  
  .BadgeCrearPagina-header-contenido-portada {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
    
    margin: -40vh auto 0;
    padding: 0 5%;
  
    filter: contrast(100%);
  }


}