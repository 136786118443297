.hide {
    max-height: 0 !important;
}

.dropdown{	
    width: auto;

	position: relative;
  	display: inline-block;

	margin: 12px 12px;

  	align-items: center;
	
	display: grid;
  	place-items: start;
}

/* Menú desplegable (Hidden by Default) */
.dropdown .menu-post{
	border-radius: 8px;
	transition: max-height .5s ease-out;
	max-height: 20em;
	overflow: hidden;

    width: min-content;
    max-height: 325px;

	margin-left: -175px;

    position: absolute;
    background-color: #fbfbfb;
    padding: 0;
  
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    z-index: 1;
}

.dropdown .menu-post .option{
	padding: 3px 10px 3px 10px;

	min-width: 100%;
	width: max-content;

	display: grid;
    place-items: center;
    
    font-size: medium;
	font-weight: normal;
    color: black;
    
    display: inline-flex;

    cursor: pointer;
}

.dropdown .menu-post .option:hover{
    background-color: #56a8ff30;
}

.pointerCursor:hover{
	cursor: pointer;
}

/* Icono Padre (Menú) */
.dropdown-icon {
    width: 30px;
    height: 30px;

    /* Altura mínima del contenedor */
    min-height: 36px;
  
    display: grid;
    place-items: center;
    
    background: #f1f2f590;
    border: none;
    
    border-radius: 50%;
    transition: 0.3s;
    padding: 2px;
    margin: 0 2px;
    opacity: 0.40;
    cursor: pointer;
}
.dropdown-icon:hover {
    /* background: transparent; */
    opacity: 0.8;

    border-radius: 4px;
}

/* Links dentro del dropdown */


/* Icono Hijo (Opciones) */
.option img {
    width: 20px;
    height: 20px;
    margin: 12px 0;
  
    display: grid;
    place-items: center;
    
    border: none;
    
    border-radius: 10%;
    transition: 0.3s;
    
    margin-right: 8px;
    opacity: 0.8;
    cursor: pointer;
}

/* Aquí van los estilos para escritorio */

/* Aquí van los estilos para tabletas grandes */
@media screen and (max-width: 991px){

    .dropdown .menu-post{
        margin-left: -155px;
    }

    /* Menú desplegable (Hidden by Default) */
    .dropdown .menu-post .option{
        padding: 0 8px 0 8px;
        font-size: smaller;
        font-weight: normal;
        color: black;
    }

    /* Icono Hijo (Opciones) */
    .option img {
        width: 16px;
        height: 16px;
        margin: 8px 0;        
        margin-right: 8px;
    }
}

/* Aquí van los estilos para tabletas medianas */
@media screen and (max-width: 767px){}

/* Aquí van los estilos para smartphones */
@media screen and (max-width: 479px){
    .dropdown .menu-post{
        margin-left: -163px;
    }
}