.Badge_crear_cuenta {
    background: #FFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    overflow: hidden;
    
    min-width: 290px;
    width: 45%;
    height: 100%;

    flex: 1 0 550px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
  }
  
  .Badge__header__crear_cuenta {
    width: 100%;
    height: 80px;

    background: #7289da20;
    display: flex;
    justify-content: center;
    
    padding: 1rem 0;
    margin: 0;
  }

  .Badge__header-icon_crear_cuenta {
    width: 50px;
    height: 50px;

    align-items: center;
  }
  
  .Badge__avatar-fondo-portada_crear_cuenta{
    display: flex;
    flex-direction: column;
    background: #FFF5E6;

    width: auto;
    height: auto;
  }

  div.contenedor-imagen-avatar {
    width: 120px;
    height: 120px;

    display: inline-block;
    position: relative;
    overflow: hidden;
  
    border-radius: 50%;
    margin: 1rem 0 0;
  }
  
  div.contenedor-imagen-avatar img {
    width: 125px;
    height: 125px;

    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Badge__section-name_crear_cuenta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0;

    color: #415164;
  }

  .Badge__section-info_crear_cuenta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    color: #B4A8A4;
    background: #f4f4f7;
  }

  .Badge__formulario {
    margin: 20px;
  }
  
  .Badge__footer_crear_cuenta {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b618f;
    font-weight: bold;
    font-size: small;
    font-style: normal;

    text-decoration: none;
  }