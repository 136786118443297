:root {

  /*---------- Tema de día ----------*/
  --light-background-component: #fff;

  /* Texto */
  --light-texto: #1D1D1F;

  --light-link-post: gray;
  --light-link-post-hover: gray;
  --light-background-link-post: #f1f2f5;
  --light-background-link-post-hover: #00000020;

  --light-icon-bottom: 0%;
  --light-icon-bottom-hover: 100%;
  --light-link-bottom: gray;
  --light-link-bottom-hover: #fff;
  --light-background-link-bottom: #00000010;

  /*---------- Tema de noche ----------*/
  --dark-background-component: #000;

  /* Texto */
  --dark-texto: #fff;

  --dark-link-post: #fff;
  --dark-link-post-hover: #4b4b4b;
  --dark-background-link-post: #4b4b4b;
  --dark-background-link-post-hover: #f1f2f5;

  --dark-icon-bottom: 100%;
  --dark-icon-bottom-hover: 0%;
  --dark-link-bottom: #fff;
  --dark-link-bottom-hover: #000;
  --dark-background-link-bottom: #dad9d9;
}

/* ------------------------------ TEMA LIGHT --------------------------- */

[theme="light"] {

  /* Padre */
  --background-component: var(--light-background-component);

  /* Texto */
  --texto: var(--light-texto);

  --link-post: var(--light-link-post);
  --link-post-hover: var(--light-link-post-hover);
  --background-link-post: var(--light-background-link-post);
  --background-link-post-hover: var(--light-background-link-post-hover);

  --icon-bottom: var(--light-icon-bottom);
  --icon-bottom-hover: var(--light-icon-bottom-hover);
  --link-bottom: var(--light-link-bottom);
  --link-bottom-hover: var(--light-link-bottom-hover);
  --background-link-bottom: var(--light-background-link-bottom);
}

/* ------------------------------ TEMA DARK ---------------------------- */

[theme="dark"] {

  /* Padre */
  --background-component: var(--dark-background-component);

  /* Texto */
  --texto: var(--dark-texto);

  --link-post: var(--dark-link-post);
  --link-post-hover: var(--dark-link-post-hover);
  --background-link-post: var(--dark-background-link-post);
  --background-link-post-hover: var(--dark-background-link-post-hover);

  --icon-bottom: var(--dark-icon-bottom);
  --icon-bottom-hover: var(--dark-icon-bottom-hover);
  --link-bottom: var(--dark-link-bottom);
  --link-bottom-hover: var(--dark-link-bottom-hover);
  --background-link-bottom: var(--dark-background-link-bottom);
}

/* ------------------------------ */

  .Navbar__publicar {
    filter: drop-shadow(0px 1px 2px #31313110);
    overflow: hidden;
    z-index: 4;
    
    width: 100%;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: 750px;
    height: 100%;
    padding: 12px 16px;
    margin: 16px auto;

    flex-direction: column;
    display: inline-block;
    justify-content: center;
    align-items: center;

    border-radius: 8px;

    background-color: var(--background-component);
  }

  /* Caja de íconos */
  .Navbar__publicar-lista-iconos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    place-items: center;
    flex-wrap: wrap;
    align-items: center;    
  }
  /* Caja de íconos */
  .Navbar__publicar-lista-iconos-top {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
  }
  
  /* Íconos */
  .Navbar__publicar-icon {
    width: 35px;
    height: 35px;

    display: grid;
    place-items: center;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    padding: 5px;
  }
  .Navbar__publicar-icon:hover {
    opacity: 1;
    background: #00000026;
  }

  /* Imagen de usuario en formato de un icono */
  div.Navbar__publicar-icon-imagen {
    width: 40px;
    height: 40px;
    cursor: pointer;

    display: inline-block;
    position: relative;
    overflow: hidden;
    
    
    border: none;
    
    border-radius: 50%;
    
    transition: 0.3s;
    margin-right: 8px;
    padding: 7px;
  }
  div.Navbar__publicar-icon-imagen:hover {
    opacity: 0.75;
    background: #00000026;
  }

  div.Navbar__publicar-icon-imagen img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    -o-object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  /* Link predeterminado */
  .Navbar__publicar-link{
    text-decoration: none;
    font-weight: normal;
    font-size: medium;
    font-family: 'Nunito';
    border-radius: 35px;

    width: 100%;
    height: 40px;
    
    padding: 0 12px;

    display: flex;
    align-items: center;

    opacity: 0.75;
    transition: 0.3s;

    color: var(--link-post);
    background: var(--background-link-post);
  }
  .Navbar__publicar-link:hover {
    opacity: 1;

    color: var(--link-post-hover);
    background: var(--background-link-post-hover);
  }

  /* Caja de íconos */
  .Navbar__publicar-lista-iconos-bottom {
    width: 100%;
    display: flex;
    justify-content: space-around;
    
    place-items: center;
    flex-wrap: wrap;
    align-items: center;
    
    margin-top: 16px;
  }

  /* Íconos */
  .Navbar__publicar-icon-bottom {
    width: 25px;
    height: 25px;

    margin-right: 5px;

    filter: invert(var(--icon-bottom));
  }
  .Navbar__publicar-icon-bottom:hover {
    filter: invert(var(--icon-bottom));
  }

  /* Link predeterminado */
  .Navbar__publicar-link-bottom{
    text-decoration: none;
    font-weight: normal;
    font-size: small;
    font-family: 'Nunito';

    display: flex;
    place-items: center;

    border-radius: 8px;
    opacity: 0.75;
    transition: 0.3s;
    padding: 4px 8px;

    color: var(--link-bottom);
  }
  .Navbar__publicar-link-bottom:hover {
    opacity: 1;

    background: var(--background-link-bottom);
    color: var(--link-bottom-hover);
  }

  /* Aquí van los estilos para escritorios grandes */
  @media screen and (min-width: 1560px){

    .Navbar__publicar {
      min-width: 44%;
      margin-inline: 10%;
    }
  }

  /* Aquí van los estilos para tabletas grandes */
  @media screen and (max-width: 991px){
    
    .Navbar__publicar {
      min-width: 90%;
    }

  }

  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){}

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    .Navbar__publicar {
      min-width: 100%;
      width: 100%;
      margin: 0 0 4px;
      border-radius: 0;
    }
    
  }