.BadgeItemsBuscador {
    background: #FFF;
    
    width: 100%;
    height: 100%;
    padding: 8px;
    margin: 2px;

    display: block;

    cursor: pointer;
    border-radius: 10px;
  }
  .BadgeItemsBuscador:hover {
    box-shadow: 0 0 8px 1px #DADDDB;
  }
  
  .BadgeItemsBuscador__header {
    width: 100%;
    height: 50px;
    background: #e7e7e740;
    display: flex;
    justify-content: space-between;
    
    cursor: default;
  }

  .BadgeItemsBuscador__header-icon {
    border-radius: 20%;
    width: 50px;
    height: 50px;

    align-items: center;
  }

  .BadgeItemsBuscador-name {
    width: 100%;

    display: block;
    padding: 0 16px;
    color: #15639e;

    font-weight: bold;
    font-size: medium;
    font-family: 'Montserrat';
  }

  .BadgeItemsBuscador-descripcion {
    width: 100%;

    display: block;
    padding: 0 16px 0;
    color: #15344b;

    font-weight: bold;
    font-size: small;
    font-family: 'Nunito';
  }

  .BadgeItemsBuscador__section-categoria {
    width: fit-content;

    display: inline-flex;

    margin-top: 16px;
    margin-left: 16px;
    padding: 4px 8px;
    color: #333333;
    background: #e7e7e750;
    border-radius: 16px;

    font-weight: bold;
    font-size: small;
    font-family: 'Nunito';

    cursor: pointer;
  }

  .BadgeItemsBuscador__section-categoria:hover {
    background: #e7e7e7;
  }

  /* Texto */
  .BadgeItemsBuscador-texto {
    width: 100%;

    margin: 10px 0 0;
    padding: 0 16px 0;
    color: #15344b90;
    background: #fff;

    font-size: smaller;
    font-weight: 400;
    font-family: 'Nunito';
    cursor: pointer;

    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;

    display: -webkit-box;
    line-clamp: 14;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
  }

  .BadgeItemsBuscador__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1rem 0 0.2rem;
  }
