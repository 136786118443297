:root {

  /*---------- Tema de día ----------*/
  --light-background-component: #f5f5f5;
  --light-background-filter-component: drop-shadow(0px 0px 1px #31313140);
  --light-divider-component: #ff885eb9;

  /* Texto */
  --light-logo: #5b5553f0;
  --light-titulo: #3a00e0;
  --light-texto: #5b5552d7;

  /* Botones */
  --light-link-menu: #313131;
  --light-link-menu-hover: #fff;

  --light-icon-footer: 0%;

  --light-link-bottom: gray;
  --light-link-bottom-hover: #fff;

  /*---------- Tema de noche ----------*/
  --dark-background-component: #000;
  --dark-background-filter-component: drop-shadow(0px 1px 1px #eaeaea40);
  --dark-divider-component: #ffe7e0e4;

  /* Texto */
  --dark-logo: #ccc8c7;
  --dark-titulo: #642eff;
  --dark-texto: #dedddc;

  /* Botones */
  --dark-link-menu: #fff;
  --dark-link-menu-hover: #000;
  --dark-background-link-menu-hover: #fff;

  --dark-icon-footer: 0%;

  --dark-link-bottom: #fff;
  --dark-link-bottom-hover: #000;
}

/* ------------------------------ TEMA LIGHT --------------------------- */

[theme="light"] {

  /* Padre */
  --background-component: var(--light-background-component);
  --background-filter-component: var(--light-background-filter-component);
  --divider-component: var(--light-divider-component);

  /* Texto */
  --logo: var(--light-logo);
  --titulo: var(--light-titulo);
  --texto: var(--light-texto);

  /* Botones */
  --link-menu: var(--light-link-menu);
  --link-menu-hover: var(--light-link-menu-hover);

  --icon-footer: var(--light-icon-footer);

  --link-bottom: var(--light-link-bottom);
  --link-bottom-hover: var(--light-link-bottom-hover);
}

/* ------------------------------ TEMA DARK ---------------------------- */

[theme="dark"] {

  /* Padre */
  --background-component: var(--dark-background-component);
  --background-filter-component: var(--dark-background-filter-component);
  --divider-component: var(--dark-divider-component);

  /* Texto */
  --logo: var(--dark-logo);
  --titulo: var(--dark-titulo);
  --texto: var(--dark-texto);

  /* Botones */
  --link-menu: var(--dark-link-menu);
  --link-menu-hover: var(--dark-link-menu-hover);

  --icon-footer: var(--dark-icon-footer);

  --link-bottom: var(--dark-link-bottom);
  --link-bottom-hover: var(--dark-link-bottom-hover);
}

/* ------------------------------ */

.footer {
  padding-block: 4rem 1rem;

  font-size: large;

  color: var(--texto);
  background: var(--background-component);
  filter: var(--background-filter-component);
}

.footer__container {
  max-width: 116rem;
  margin: auto;
  padding: 0 1.5rem;
  overflow: none;
  resize: horizontal;
}

.footer__top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  row-gap: 2rem;
}

.footer__bottom {
  position: absolute;
  display: inline-flex;
  margin: -24px 16px 32px;
}

.company {
  padding-right: 0rem;
}
.company__logo {
  color: var(--logo);
}
.company__description {
  font-size: smaller;
}
.copyright {
  margin-top: auto;
  font-size: small;
}

.footer__column {
  margin: 0 auto;
}

.footer__title {
  margin-bottom: 2rem;

  font-weight: bold;
  font-family: 'Montserrat';
  font-size: x-large;

  color: var(--titulo);
}

.footer__list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.footer__list-icon {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;

  filter: invert(var(--icon-footer));
}

.footer__list-link {
  text-decoration: none;
  color: inherit;
  transition: color .25s;

  font-family: 'Source Sans 3';
  font-weight: normal;
  font-size: medium;
}

.footer__list-link:hover {
  color: #ff885e;
}

.footer__divider {
  margin-block: 2.5rem;
  border: none;
  border-top: 1px solid var(--divider-component);
}

/* Aquí van los estilos para tabletas medianas */
/* @media screen and (max-width: 767px){ */
@media screen and (max-width: 900px){

  .footer {
    padding-block: 5rem;
    font-size: medium;

    /* margin-bottom: 65px; */
  }
  
  .footer__top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    row-gap: 2rem;
  }

  .footer__bottom {
    margin: 16px 24px 16px;
  }

  .footer__column {
    margin: 0 auto;
  }

  .footer__title {
    margin-bottom: 1rem;
    font-size: large;
  }

  .footer__list {
    gap: 1rem;
  }

}

/* Aquí van los estilos para smartphones */
@media screen and (max-width: 479px){

  .footer {
    padding-block: 4rem 3rem;
  }
  
  .footer__top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    row-gap: 2.5rem;
  }

  .footer__bottom {
    margin: 10px 5px 16px;
  }

  .footer__column {
    margin: 0 1rem;
  }

  .footer__title {
    margin-bottom: 1rem;
    font-size: medium;
  }

  .footer__list {
    gap: 0.5rem;
  }

}