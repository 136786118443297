.BadgeMencion {
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    
    
    width: 100%;
    max-width: 300px;

    height: 100%;

    flex: 1 1 270px;
    flex-direction: column;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 2%;

    white-space: nowrap;

    border-radius: 10px;
  }
  .BadgeMencion:hover {
    box-shadow: 0 0 8px 1px #DADDDB;
  }
  
  .BadgeMencion__header {
    width: 100%;
    height: 50px;
    background: #e7e7e740;
    display: flex;
    justify-content: space-between;
    
    cursor: default;
  }

  .BadgeMencion__header-icon {
    border-radius: 20%;
    width: 20px;
    height: 20px;

    display: inline;
    justify-content: space-between;
    align-items: center;
  }
  
  .BadgeMencion__section-logotipo{
    width: 100px;
    height: 100px;
    object-fit: cover;

    margin: 16px 16px;

    border-radius: 50%;
  }


  .BadgeMencion__section-titulo {
    width: 100%;

    display: block;
    padding: 0px 16px 0;
    color: #15344b;

    font-weight: bold;
    font-size: large;
    font-family: 'Montserrat';
  }

  .BadgeMencion__section-subtitulo {
    width: 100%;

    display: inline;
    padding: 0 16px 0;
    color: #15344b;

    font-weight: bold;
    font-size: small;
    font-family: 'Nunito';
  }

  .BadgeMencion__section-categoria {
    width: fit-content;

    display: inline-flex;

    margin-top: 16px;
    margin-left: 16px;
    padding: 4px 8px;
    color: #333333;
    background: #e7e7e750;
    border-radius: 16px;

    font-weight: bold;
    font-size: small;
    font-family: 'Nunito';

    cursor: auto;
  }

  .BadgeMencion__section-categoria:hover {
    background: #e7e7e7;
  }

  /* Texto */
  .BadgeMencion__section-texto {
    width: 100%;

    margin: 10px 0 0;
    padding: 0 16px 0;
    color: #0e2538df;
    background: #fff;

    font-size: smaller;
    font-weight: 400;
    font-family: 'Nunito';
    cursor: text;

    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;

    display: -webkit-box;
    line-clamp: 14;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
  }

  .BadgeMencion__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1rem 0 0.2rem;
  }
  

  .BadgeMencion__section-urlCita {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #15344bde;
    background: #e7e7e750;

    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    border-color: transparent;
    border-top: #ff885e90;
    border-width: 1px;
    border-style: solid;

    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    cursor: pointer;
  }
  .BadgeMencion__section-urlCita:hover {
    box-shadow: 0 0 8px 1px #ff885e70;
    color: #045c9f;
  }