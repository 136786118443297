.BadgeCrearEntrada {
    background: #FFF;
    overflow: hidden;

    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    padding: 16px;
    margin: 0 auto 0;
}

  /* Imagen de portada */
  .BadgeCrearEntrada-portada {
    height: 40vh;
    width: 100%;
    background: #f9fafc;
    filter: drop-shadow(0px 1px 1px #31313110);
  }
  
  .BadgeCrearEntrada-imagen-portada {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 25/4;
    
    opacity: 0.20;
    -moz-opacity: 0.20;
    filter:alpha(opacity=20);
  }

  /* Contenido de portada */  
  .BadgeCrearEntrada-header-contenido-portada {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
    
    margin: -40vh auto 0;
    padding: 0 5%;
  
    filter: contrast(100%);
  }

  .btn.btn-seleccionar-foto {
    width: auto;
    height: 20vh;
    font-size: large;
  
    color: #31313190;
    background-color: transparent;
    border-color: transparent;
  
    margin: 10vh 20px;
  }
  
  .btn.btn-seleccionar-foto:hover {
    color: #31313190;
    background-color: #ffffff20;
  }

  .btn.btn-mostrar-foto {
    width: min-content;
    height: 20vh;
    font-size: large;
  
    color: #31313190;
    background-color: #ffffff20;
    border-color: transparent;
  
    margin: 10vh 20px;
  }
  
  .btn.btn-mostrar-foto:hover {
    color: #31313190;
    background-color: transparent;
  }

  /* Título */
  .BadgeCrearEntrada-titulo {
    width: 100%;
    overflow: auto;

    margin: 20px 0 0;
    padding: 8px;

    border: transparent;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;

    font-size: 32px;
    font-family: 'Montserrat';
    font-weight: bolder;
    color: #45484e;
  }
  .BadgeCrearEntrada-titulo:hover {
    background: #f1f2f590;
  }

  /* Subtítulo */
  .BadgeCrearEntrada-subtitulo {
    width: 100%;
    
    margin-bottom: 16px;
    padding: 8px;

    border: transparent;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;

    font-size: 20px;
    font-family: 'Nunito';
    font-weight: bold;
    color: 	#7289da;
  }
  .BadgeCrearEntrada-subtitulo:hover {
    background: #f1f2f590;
  }


  /* Botón agregar Bloque */
  .BadgeCrearEntrada-nuevoBloque {
    width: 90%;
    height: auto;
    
    display: grid;
    place-items: center;

    background: transparent;
    margin: 24px 5% 0;
    padding-top: 8px;

    border: 0;
    border-top: 2px;
    border-color: transparent;
    border-style: solid;

    transition: 0.3s;
    opacity: 0.4;
  }
  .BadgeCrearEntrada-nuevoBloque:hover {
    border-color: #BCE4FF;
    opacity: 1;
  }

  .BadgeCrearEntrada-icon-nuevoBloque {
    width: 25px;
    height: 25px;
  
    display: grid;
    place-items: center;
    
    background: #f1f2f5;
    border: none;
    
    border-radius: 10%;
    transition: 0.3s;
    padding: 2px;
    margin: 0 2px;
    opacity: 1;
    cursor: pointer;
  }
  .BadgeCrearEntrada-icon-nuevoBloque:hover {
    opacity: 0.8;
    background: #f1f2f5;
  }

  /* Contenedor padre (lista) */
  .BadgeCrearEntrada-textarea-padre {
    width: 100%;
    display: block;
  }
  

  /* Opciones (barra de funciones) */
  .BadgeCrearEntrada-opciones {
    width: 100%;
    height: auto;

    margin-top: 40px;
    
    margin-bottom: 16px;

    display: flex;
    justify-content: space-between;
  }

  /* Opciones (SEO) */
  .BadgeCrearEntrada-seo {
    width: 100%;
    height: auto;

    margin-top: 40px;
    
    margin-bottom: 16px;

    display: flex;
    justify-content: space-evenly;
  }
  .BadgeCrearEntrada-url{
    width: 25vmax;
    height: auto;
    min-height: 36px;

    margin: 4px 0;
    padding: 4px 8px;

    overflow: hidden;
    resize: vertical;

    border: #4a4b4b;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;

    color: black;
    transition: 0.3s;
    opacity: 1;
  }

  .BadgeCrearEntrada-descripcion{
    width: 55vmax;
    height: auto;
    min-height: 36px;

    margin: 4px 0;
    padding: 4px 8px;

    overflow: hidden;
    resize: vertical;

    border: #4a4b4b;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;

    color: black;
    transition: 0.3s;
    opacity: 1;
  }

  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

    /* Opciones (barra de funciones) */
    .BadgeCrearEntrada-opciones {
      width: 100%;
      height: auto;

      margin-inline: 0;

      display: block;
    }

  }
 
  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    .BadgeCrearEntrada { 
      padding: 0px;
      margin: 0;
    }

    /* Contenido de portada */  
    .BadgeCrearEntrada-header-contenido-portada {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: stretch;
      
      margin: -40vh auto 0;
      padding: 0 5%;
    
      filter: contrast(100%);
    }

    .BadgeCrearEntrada-url{
      min-width: 160px;
    }
  
    .BadgeCrearEntrada-descripcion{
      width: 32vmax;
    }


  }