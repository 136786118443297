  .BadgeContacto-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 300px;
    max-width: 600px;
    margin: 0;
    
    background-color: #f5f5f5;
    padding: 30px 36px 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  }
  
  .BadgeContacto-h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #333;
  }
  
  .BadgeContacto-label {
    width: 90%;
    display: block;
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .BadgeContacto-input,
  .BadgeContacto-input,
  .BadgeContacto-textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }

  .BadgeContacto-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .BadgeContacto-button:hover {
    background-color: #0069d9;
  }