.TarjetaPerfil {
    background: #FFF;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    overflow: hidden;
    
    min-width: 250px;
    max-width: 370px;
    width: 100%;

    min-height: 350px;
    max-height: 500px;
    height: 100%;

    flex: 1 1 550px;
    flex-direction: column;
    display: flex;
    align-items: center;

    margin: auto;
  }
  
  .TarjetaPerfil__header {
    width: 100%;
    height: 80px;
    
    background: #7289da30;
    display: flex;
    justify-content: flex-end;
  }

  .TarjetaPerfil__header-icon {
    border-radius: 20%;
    width: 50px;
    height: 50px;

    align-items: center;
  }
  
  .TarjetaPerfil__avatar-fondo-portada{
    display: flex;
    flex-direction: column;
    background: #FFF5E6;

    width: auto;
    height: auto;
  }


  .TarjetaPerfil__avatar {
    border-radius: 50%;

    margin-top: -70px;
    margin-bottom: 1.5rem;

    object-fit: cover;
    -o-object-fit: cover;
    width: 140px;
    height: 140px;
  }

  .TarjetaPerfil__section-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    color: #415164;
  }

  .TarjetaPerfil__section-biografia {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #ff885e;
    background: #fcfcff;
    border-radius: 10px;

    width: 90%;

    font-size: medium;
    font-weight: normal;
    font-family: 'Nunito', 'Poppins';

    margin: 0;
  }

  .TarjetaPerfil__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b618f;

    margin: 1rem 0 1rem 0;
  }
  
  .TarjetaPerfil__paginaWeb {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b618f;
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    background-color: transparent;
    border: transparent;

    margin: 0 0 1rem 0;
  }

  .TarjetaPerfil__paginaWeb:hover {
    color: #7289da;
    text-decoration: underline;
  }


/* Aquí van los estilos para smartphones */
@media screen and (max-width: 900px){

  /* Padre */
  .TarjetaPerfil {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  /* Biografía */
  .TarjetaPerfil__section-biografia {
    padding: 0.5rem;
    border-radius: 10px;
    width: 90%;
    font-size: medium;
  }

}