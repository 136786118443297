.Sidebar {
  display: flex;
  flex-wrap: wrap;

  width: 250px;
  height: 100%;

  position: fixed;
  top: 0;
  right: 0;
  
  padding: 1rem 16px;

  background: #fff;
  filter: drop-shadow(0px -1px 1px #31313140);

  transform: translate(250px);
  transition: 0.3s;
}

/* Se remplazó por .SidebarAdministrador-izquierdo */
/*
.Sidebar-izquierdo {
  display: flex;
  flex-wrap: wrap;

  width: 250px;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  padding: 1rem 16px;

  background: #fff;
  filter: drop-shadow(0px -1px 1px #31313140);

  transform: translate(-250px);
  transition: 0.3s;
} */

  .Sidebar-list{
    list-style: none;
    padding: 0;
  }

  /* Texto */
  .Sidebar__fuente {
    font-weight: bold;
    font-size: large;
    font-family: 'Nunito';
    color: #000;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /* Íconos */
  .Sidebar-icon {
    width: 32px;
    height: 32px;

    display: grid;
    place-items: center;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    margin: 2px 12px 2px 0;
    padding: 5px;
  }
  .Sidebar-icon:hover {
    border-radius: 25%;
    opacity: 1;
    background: #00000026;
  }

  /* Link predeterminado */
  .Sidebar-link{
    text-decoration: none;
    font-weight: bolder;
    font-size: medium;
    font-family: 'Montserrat';
    color: #7289da;

    width: 100%;
    display: inline-flex;
    place-items: center;
    margin-bottom: 0.7rem;
  }
  .Sidebar-link:hover {
    color: #ff885e;
  }

  /* Link principal */
  .Sidebar-link-principal{
    text-decoration: none;
    font-weight: bolder;
    font-size: medium;
    font-family: 'Montserrat';
    color: #110964;

    display: block;
    place-items: center;
    margin-bottom: 1rem;
  }
  .Sidebar-link-principal:hover{
    color: #ff885e;
  }


  /* Ícono anclado abajo */
  .Sidebar-icon-bottom {
    width: 25px;
    height: 25px;

    display: grid;
    place-items: center;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    margin: 2px 8px 2px 0;
    padding: 5px;
  }
  .Sidebar-icon-bottom:hover {
    opacity: 1;
    background: #00000026;
  }

  /* Link anclado abajo */
  .Sidebar-link-bottom{
    text-decoration: none;
    font-weight: bolder;
    font-size: smaller;
    font-family: 'Nunito';
    color: #313131;
    background: transparent;

    border: none;

    position: fixed;
    left: 16px;
    bottom: 0;

    display: inline-flex;
    place-items: center;
  }
  .Sidebar-link-bottom:hover{
    color: #7289da;
  }


  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

    /* Íconos */
    .Sidebar-icon {
      width: 30px;
      height: 30px;
    }

    /* Link predeterminado */
    .Sidebar-link{
      font-size: smaller;
      margin-bottom: 8px;
    }

    /* Link principal */
    .Sidebar-link-principal{
      font-size: smaller;
      margin-bottom: 8px;
    }

    /* Link anclado abajo */
    .Sidebar-link-bottom{
      bottom: 1.6rem;
    }

  }