.BadgeEntrada {
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    
    width: 100%;
    max-width: 300px;

    height: 100%;

    flex: 1 1 270px;
    flex-direction: column;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 2%;

    white-space: nowrap;

    cursor: pointer;
    border-radius: 10px;
  }
  .BadgeEntrada:hover {
    box-shadow: 0 0 8px 1px #DADDDB;
  }
  
  .BadgeEntrada__header {
    width: 100%;
    height: 50px;
    background: #e7e7e740;
    display: flex;
    justify-content: space-between;
    
    cursor: default;
  }

  .BadgeEntrada__header-icon {
    border-radius: 20%;
    width: 50px;
    height: 50px;

    align-items: center;
  }
  
  .BadgeEntrada__section-imagen-portada{
    width: 100%;
    height: 160px;
    object-fit: cover;
  }


  .BadgeEntrada__section-titulo {
    width: 100%;

    display: block;
    padding: 16px 16px 0;
    color: #15344b;

    font-weight: bold;
    font-size: large;
    font-family: 'Montserrat';
  }

  .BadgeEntrada__section-subtitulo {
    width: 100%;

    display: inline;
    padding: 0 16px 0;
    color: #15344b;

    font-weight: bold;
    font-size: small;
    font-family: 'Nunito';
  }

  .BadgeEntrada__section-categoria {
    width: fit-content;

    display: inline-flex;

    margin-top: 16px;
    margin-left: 16px;
    padding: 4px 8px;
    color: #333333;
    background: #e7e7e750;
    border-radius: 16px;

    font-weight: bold;
    font-size: small;
    font-family: 'Nunito';

    cursor: pointer;
  }

  .BadgeEntrada__section-categoria:hover {
    background: #e7e7e7;
  }

  /* Texto */
  .BadgeEntrada__section-texto {
    width: 100%;

    margin: 10px 0 0;
    padding: 0 16px 0;
    color: #15344b90;
    background: #fff;

    font-size: smaller;
    font-weight: 400;
    font-family: 'Nunito';
    cursor: pointer;

    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;

    display: -webkit-box;
    line-clamp: 14;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
  }

  .BadgeEntrada__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1rem 0 0.2rem;
  }
  

  .BadgeEntrada__horaProgramado {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #15344b90;
    background: #e7e7e750;
    
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    border-color: transparent;
    border-top: #ff885e90;
    border-width: 1px;
    border-style: solid;

    margin-top: 20px;
    padding-top: 10px;
  }

  .BadgeEntrada__usuarioProgramado {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #15344b90;
    background: #e7e7e750;

    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    padding-bottom: 10px;
  }