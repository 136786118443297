:root {
  --toast-background: #292B2D;
  --toast-success: #9CE6A8;
  --text: white;
}

.snackbar-index {
  background-color: var(--background);
  display: grid;
  place-items: center;
  font-size: 16px;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 48px;
}

.notification {
  position: absolute;
  align-items: center;
  border-radius: 8px;
  background-color: var(--toast-background);
  color: var(--text);
  max-width: 320px;
  width: 100%;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  animation: fade-in 3.5s linear;
  border-radius: 12px;
}

.notification__description {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1rem;
  flex-grow: 2;
}

.notification__icon {
  height: 26px;
  width: 26px;
  margin-right: 4px;
}

.notification__body {
  display: flex;
  flex-direction: row;
  padding: 12px;
}

.notification__button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  font-size: 1.1rem;
  white-space: nowrap;
  margin-left: 20px;
  flex-grow: 1;
  color: var(--text);
}

.notification__button:hover {
  text-decoration: underline;
}


.notification__progress {
  position: absolute;
  left: 8px;
  bottom: 4px;
  width: calc(100% - 16px);
  height: 3px;
  transform: scaleX(0);
  transform-origin: left;
  background: linear-gradient(
      to right, 
      var(--toast-background),  
      var(--toast-success)
  );
  border-radius: inherit;
  animation: progress 2.5s 0.3s linear;
}

.icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--toast-success);
  margin-right: 8px;
  padding: 6px;
}

.icon__wrapper svg {
  stroke: var(--toast-background);
  stroke-width: 3px;
}

@keyframes fade-in {
  5% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
  }
  95% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes progress {
  to {
      transform: scaleX(1);
  }
}