.BadgeAgregarItemBuscador {
    background: #FFF;
    overflow: hidden;

    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    padding: 16px;
    margin: 0 auto 0;
}

  /* Name */
  .BadgeAgregarItemBuscador-name {
    width: 100%;
    overflow: hidden;

    margin: 20px 0 16px;
    padding: 8px;

    border-style: solid;
    border-radius: 4px;
    border-width: 1px;

    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: bolder;
    color: #45484e;
  }
  .BadgeAgregarItemBuscador-name:hover {
    background: #0099ff71;
  }

  /* Descripción */
  .BadgeAgregarItemBuscador-descripcion {
    width: 100%;
    overflow: hidden;
    
    margin-bottom: 8px;
    padding: 8px;

    border-style: solid;
    border-radius: 4px;
    border-width: 1px;

    font-size: 20px;
    font-family: 'Nunito';
    font-weight: normal;
    color: 	#45484e;
  }
  .BadgeAgregarItemBuscador-descripcion:hover {
    background: #FF843271;
  }

  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

  }
 
  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    .BadgeAgregarItemBuscador { 
      padding: 0px;
      margin: 0;
    }

  }