.Navbar__bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  
  width: 100%;
  height: 65px;
  padding: 4px 32px 0;
  background-color: #fff;

  filter: drop-shadow(0px 0px 4px #31313140);
  z-index: 8;
  
  position: fixed;
  bottom: 0;

  opacity: 0;
  visibility: hidden;
}

  /* Caja de íconos */
  .Navbar__bottom-lista-iconos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    place-items: center;
    flex-wrap: wrap;
    align-items: center;
  }
  
  /* Íconos */
  .Navbar__bottom-icon {
    width: 32px;
    height: 32px;

    display: grid;
    place-items: center;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    padding: 5px;
  }
  .Navbar__bottom-icon:hover {
    opacity: 1;
    background: #00000026;
  }

  /* Imagen de usuario en formato de un icono */
  div.Navbar__bottom-icon-imagen {
    width: 40px;
    height: 40px;
    cursor: pointer;

    display: inline-block;
    position: relative;
    overflow: hidden;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    margin: 0 8px;
    padding: 7px;
  }
  div.Navbar__bottom-icon-imagen:hover {
    opacity: 1;
    background: #00000026;
  }

  div.Navbar__bottom-icon-imagen img {
    width: 43px;
    height: 43px;
    object-fit: cover;
    -o-object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  /* Link predeterminado */
  .Navbar__bottom-link{
    text-decoration: none;
    font-weight: normal;
    font-size: small;
    font-family: 'Nunito';
    color: gray;

    display: inline;
  }
  .Navbar__bottom-link:hover {
    color: #7289da;
  }



  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

    .Navbar__bottom {
      opacity: 1;
      visibility: visible;
    }

  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){}