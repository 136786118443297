  
:root {

  /*---------- Tema de día ----------*/
  --light-background: #fff;
  --light-border: #0000001a;

  /* Autor */
  --light-autor-nombre: #0F1419;
  --light-autor-usuario: #536471;
  /* Categoría */
  --light-categoria-texto: #333333;
  --light-categoria-background: #e7e7e750;
  --light-categoria-background-hover: #e7e7e7;
  /* Texto */
  --light-texto: #191919;
  /* Fecha */
  --light-fecha: #15344b90;
  /* Íconos */
  --light-icon-bottom: 0%;
  --light-icon-bottom-hover: 100%;

  /*---------- Tema de noche ----------*/
  --dark-background: #191919;
  --dark-border: #ffffff92;

  /* Autor */
  --dark-autor-nombre: #fff;
  --dark-autor-usuario: #a3c3db;
  /* Categoría */
  --dark-categoria-texto: #eaeaea;
  --dark-categoria-background: #454545;
  --dark-categoria-background-hover: #adadad;
  /* Texto */
  --dark-texto: #fff;
  /* Fecha */
  --dark-fecha: #a6d1ee;
  /* Íconos */
  --dark-icon-bottom: 100%;
  --dark-icon-bottom-hover: 0%;
}

/* ------------------------------ TEMA LIGHT --------------------------- */

[theme="light"] {

  /* Padre */
  --background: var(--light-background);
  --border: var(--light-border);

  /* Autor */
  --autor-nombre: var(--light-firma-nombre);
  --autor-usuario: var(--light-firma-usuario);
  /* Categoría */
  --categoria-texto: var(--light-categoria-texto);
  --categoria-background: var(--light-categoria-background);
  --categoria-background-hover: var(--light-categoria-background-hover);
  /* Texto */
  --texto: var(--light-texto);
  /* Fecha */
  --fecha: var(--light-fecha);
  /* Íconos */
  --icon-bottom: var(--light-icon-bottom);
  --icon-bottom-hover: var(--light-icon-bottom-hover);
}

/* ------------------------------ TEMA DARK ---------------------------- */

[theme="dark"] {

  /* Padre */
  --background: var(--dark-background);
  --border: var(--dark-border);

  /* Autor */
  --autor-nombre: var(--dark-firma-nombre);
  --autor-usuario: var(--dark-firma-usuario);
  /* Categoría */
  --categoria-texto: var(--dark-categoria-texto);
  --categoria-background: var(--dark-categoria-background);
  --categoria-background-hover: var(--dark-categoria-background-hover);
  /* Texto */
  --texto: var(--dark-texto);
  /* Fecha */
  --fecha: var(--dark-fecha);
  /* Íconos */
  --icon-bottom: var(--dark-icon-bottom);
  --icon-bottom-hover: var(--dark-icon-bottom-hover);
}

/* ------------------------------ */  
  
  
  .BadgePost {
      box-shadow: 0 0 2px 0 var(--border);
      overflow: hidden;
      
      width: 100%;
      min-width: 700px;
      max-width: 800px;
      height: 100%;
      margin: 1px auto;

      flex-direction: column;
      display: inline-block;
      justify-content: center;
      align-items: center;

      border-color: transparent;
      border-bottom: gainsboro;
      border-width: 0.5px;
      border-style: solid;

      cursor: pointer;

      background: var(--background);
  }
  
  /* Header */
  .BadgePost__header {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: default;

    background: var(--background);
  }

  /* Autor */
  .BadgePost__autor {
    width: 100%;
    height: auto;
    
    display: flex;
    margin: 16px 16px 0;
  }

  /* Autor - imagen */
  div.BadgePost__autor-imagen {
    width: 45px;
    height: 45px;
    cursor: pointer;

    display: inline-block;
    position: relative;
    overflow: hidden;
    
    border: none;
    
    border-radius: 50%;
    transition: 0.3s;
    margin-right: 8px;
    padding: 0;
  }
  div.BadgePost__autor-imagen:hover {
    opacity: 0.75;
  }

  div.BadgePost__autor-imagen img {
    width: 47px;
    height: 47px;
    object-fit: cover;
    -o-object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Autor - nombre público */
  .BadgePost__autor-nombre-publico {
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: bolder;

    color: var(--autor-nombre);
  }
  .BadgePost__autor-nombre-publico:hover {
    color: #0F1419;
  }

  /* Autor - nombre usuario */
  .BadgePost__autor-nombre-usuario {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 500;

    color: var(--autor-usuario);
  }
  .BadgePost__autor-nombre-usuario:hover {
    color: #536471;
  }

  /* Firma del Autor -- verificado */
  .BadgePost__autor-verificado {
    height: 18px;
    width: 18px;

    margin-left: 5px;
    margin-right: 5px;
  }

  /* Hora de publicación */
  .BadgePost__fecha {
    width: 100%;    
    font-weight: normal;
    font-size: small;
    font-style: normal;
    
    margin-top: -1px;
    margin-left: 1px;
    
    color: var(--fecha);
  }

  /* Íconos */
  .BadgePost__header-icon {
    align-items: center;
    margin: 16px 12px;
    
    width: 32px;
    height: 32px;

    display: grid;
    place-items: center;
    
    background: transparent;
    border: none;
    
    opacity: 0.50;
    transition: 0.3s;
    padding: 5px;

    border-radius: 50%;
  }
  .BadgePost__header-icon:hover {
    opacity: 0.75;
    background: #E4E6EB;
  }

  /* Categoría */
  .BadgePost__section-categoria {
    width: fit-content;

    display: inline-flex;

    margin-top: 16px;
    margin-left: 24px;
    padding: 4px 8px;
    border-radius: 16px;

    font-weight: bold;
    font-size: small;
    font-family: 'Nunito';

    cursor: pointer;

    background-color: var(--categoria-background);
    color: var(--categoria-texto);
  }
  .BadgePost__section-categoria:hover {
    background-color: var(--categoria-background-hover);
  }

  /* Texto */
  .BadgePost__section-texto {
    width: 100%;

    margin: 10px 0 30px;
    padding: 0 24px 0;

    font-size: medium;
    font-weight: 400;
    font-family: 'Nunito';
    cursor: pointer;

    overflow: hidden;
    white-space: pre-wrap;
    /* text-overflow: ellipsis; */

    display: -webkit-box;
    line-clamp: 7;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;

    background: var(--background);
    color: var(--texto);
  }

  /* Íconos */
  .BadgePost__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1rem 0 0.2rem;
  }

  /* Imagen de portada */
  .BadgePost__section-imagen-portada{
    width: 100%;
    height: 200px;
    object-fit: cover;

    display: flex;
  }

  /* Footer */
  .BadgePost__footer {
    width: 100%;
    height: 100%;

    margin-top: 16px;
    padding: 8px 0;
    display: flex;
    justify-content: space-around;
    
    place-items: center;
    flex-wrap: wrap;
    align-items: center;

    /* background: #e7e7e700; */
    border-color: transparent;
    /* border-top: gainsboro; */
    border-top: transparent;
    border-width: 0.5px;
    border-style: solid;

    cursor: default;
  }

  /* Íconos */
  .BadgePost__footer-icon {
    width: 32px;
    height: 32px;

    display: grid;
    place-items: center;
    
    background: transparent;
    border: none;
    
    opacity: 0.50;
    transition: 0.3s;
    padding: 5px;

    border-radius: 50%;

    filter: invert(var(--icon-bottom));
  }
  .BadgePost__footer-icon:hover {
    opacity: 0.75;
    background: #E4E6EB;

    filter: invert(var(--icon-bottom));
  }

  /* Íconos */
  .BadgePost__footer-numero {
    width: 32px;
    height: 32px;
    display: flex;
    font-size: smaller;

    place-items: center;
    
    transition: 0.3s;
    padding: 5px;

    color: var(--fecha);
  }

  

  /* Aquí van los estilos para escritorios grandes */
  @media screen and (min-width: 1560px){

    .BadgePost {
      min-width: 55%;
      margin-inline: 10%;
    }

    /* Categoría */
    .BadgePost__section-categoria {
      font-size: medium;
    }

    /* Texto */
    .BadgePost__section-texto {
      margin: 28px auto;
      font-size: larger;

      line-clamp: 10;
      -webkit-line-clamp: 10;
    }


    /* Footer */
    .BadgePost__footer {
      width: 90%;
      padding: 8px 16px 8px 67px;
      
      justify-content: space-evenly;
    }

  }

  /* Aquí van los estilos para tabletas grandes */
  @media screen and (max-width: 991px){

    .BadgePost {
      min-width: 90%;
    }
  }

  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

    .BadgePost {
      min-width: 100%;
    }

    /* Categoría */
    .BadgePost__section-categoria {
      margin-top: 8px;
      margin-left: 67px;
    }

    /* Texto */
    .BadgePost__section-texto {
      width: 100%;

      margin: 10px 0;
      padding-left: 70px;
    }


    /* Footer */
    .BadgePost__footer {
      width: 90%;
      padding: 8px 16px 8px 67px;
      
      justify-content: space-between;
    }

  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){
    
    
  }