@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.BadgeLeerPagina {
  
    overflow: hidden;

    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    padding: 0 0%;
    margin: 0 auto 0;

    background: #FFF;
  }


  /* Imagen de portada */
  .BadgeLeerPagina-portada {
    height: 70vh;
    background: #f9fafc;
    filter: drop-shadow(0px 1px 1px #31313120);
  }
  
  .BadgeLeerPagina-imagen-portada {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 35/9;
    
    opacity: 0.20;
    -moz-opacity: 0.20;
    filter:alpha(opacity=20);
  }


  /* Título */
  .BadgeLeerPagina-titulo {
    width: 100%;
    padding: 1rem 0.5rem;

    font-size: 300%;
    line-height: 50px;
    font-weight: 700;
    letter-spacing: 0px;
      
    font-family: 'Montserrat';
    color: #45484e;
  }

  /* Subtítulo */
  .BadgeLeerPagina-subtitulo {
    width: 100%;
    padding: 0 0.5rem;

    font-size: 160%;
    line-height: 45px;
    font-weight: 600;
    letter-spacing: 0px;

    font-family: 'Montserrat';
    color: 	#7289da;
  }

  /* Hora y Fecha */
  .BadgeLeerPagina-fecha {
    line-height: 25px;
    letter-spacing: 0px;
    font-family: 'Source Sans 3';
    font-size: medium;

    width: 75%;
    height: 100%;
    padding: 0;
    color: #536471;
    
    margin: 50px auto 25px;
    display: block;
    justify-content: center;
    align-items: center;
  }
  
  /* Texto */
  .BadgeLeerPagina-texto {
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 17px;
    width: 75%;
    height: auto;

    margin: 20px auto 50px;
    padding: 0;
    color: #000;
    
    display: block;
    white-space: pre-wrap;
  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 900px){

    /* Imagen de portada */
    .BadgeLeerPagina-portada {
      height: 50vh;
      background: #f9fafc;
      filter: drop-shadow(0px 1px 1px #31313120);
    }

    /* Hora y Fecha */
    .BadgeLeerPagina-fecha {
      width: 86%;
      font-size: 15px;
    }

    /* Texto */
    .BadgeLeerPagina-texto {
      width: 86%;
      
      margin: 20px auto;
      padding: 0;
      display: block;
    }

  }
 