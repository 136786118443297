@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700');

:root {

  /*---------- Tema de día ----------*/
  --light-background: #fff;

  /* Firma */
  --light-firma-nombre: #0F1419;
  --light-firma-usuario: #536471;
  /* Categoría */
  --light-categoria-texto: #333333;
  --light-categoria-background: #e7e7e750;
  --light-categoria-background-hover: #e7e7e7;
  /* Texto */
  --light-texto: #1D1D1F;
  /* Fecha */
  --light-fecha: #536471;

  /*---------- Tema de noche ----------*/
  --dark-background: #191919;

  /* Firma */
  --dark-firma-nombre: #fff;
  --dark-firma-usuario: #a3c3db;
  /* Categoría */
  --dark-categoria-texto: #eaeaea;
  --dark-categoria-background: #454545;
  --dark-categoria-background-hover: #adadad;
  /* Texto */
  --dark-texto: #fff;
  /* Fecha */
  --dark-fecha: #a6d1ee;
}

/* ------------------------------ TEMA LIGHT --------------------------- */

[theme="light"] {

  /* Padre */
  --background: var(--light-background);

  /* Firma */
  --firma-nombre: var(--light-firma-nombre);
  --firma-usuario: var(--light-firma-usuario);
  /* Categoría */
  --categoria-texto: var(--light-categoria-texto);
  --categoria-background: var(--light-categoria-background);
  --categoria-background-hover: var(--light-categoria-background-hover);
  /* Texto */
  --texto: var(--light-texto);
  /* Fecha */
  --fecha: var(--light-fecha);
}

/* ------------------------------ TEMA DARK ---------------------------- */

[theme="dark"] {

  /* Padre */
  --background: var(--dark-background);

  /* Firma */
  --firma-nombre: var(--dark-firma-nombre);
  --firma-usuario: var(--dark-firma-usuario);
  /* Categoría */
  --categoria-texto: var(--dark-categoria-texto);
  --categoria-background: var(--dark-categoria-background);
  --categoria-background-hover: var(--dark-categoria-background-hover);
  /* Texto */
  --texto: var(--dark-texto);
  /* Fecha */
  --fecha: var(--dark-fecha);
}

/* ------------------------------ */

  .BadgeLeerPost {
    overflow: hidden;

    display: block;
    justify-content: center;
    
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 70vh;

    padding: 0 0px;
    margin: 0 auto 0;

    background-color: var(--background);
  }


  /* Firma del Autor -- CONTENEDOR PADRE */
  .BadgeLeerPost-firma {
    width: 68%;
    height: auto;
    
    display: flex;
    margin: 35px auto 0;
  }

  /* Firma del Autor -- avatar */
  .BadgeLeerPost-firma-avatar {
    object-fit: cover;
    -o-object-fit: cover;

    height: 52px;
    width: 52px;
    border-radius: 50%;
    
    margin-right: 10px;
  }

  /* Firma del Autor -- nombre */
  .BadgeLeerPost-firma-nombre {
    font-family: 'Montserrat';
    font-size: 19px;
    font-weight: bolder;

    color: var(--firma-nombre);
  }

  /* Firma del Autor -- nombre */
  .BadgeLeerPost-firma-usuario {
    display: block;
    font-family: 'Nunito';
    font-size: 17px;
    font-weight: 500;

    margin-top: -7px;

    color: var(--firma-usuario);
  }

  /* Firma del Autor -- verificado */
  .BadgeLeerPost-firma-verificado {
    height: 20px;
    width: 20px;

    margin-left: 5px;
  }

  

  /* Imagen de portada */
  .BadgeLeerPost-portada {
    height: 70vh;
    background: #f9fafc;
    filter: drop-shadow(0px 1px 1px #31313120);
  }
  
  .BadgeLeerPost-imagen-portada {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 35/9;
    
    opacity: 0.20;
    -moz-opacity: 0.20;
    filter:alpha(opacity=20);
  }


  /* Categoría */
  .BadgeLeerPost-categoria {
    width: fit-content;

    display: inline-block;

    margin: 32px 16% 8px;
    padding: 4px 8px;
    border-radius: 16px;

    font-weight: bold;
    font-size: medium;
    font-family: 'Nunito';

    cursor: pointer;

    background-color: var(--categoria-background);
    color: var(--categoria-texto);
  }
  .BadgeLeerPost-categoria:hover {
    background-color: var(--categoria-background-hover);
  }
  
  /* Texto */
  .BadgeLeerPost-texto {
    font-family: 'Nunito';
    font-weight: 500;
    width: 68%;
    height: auto;

    margin: 8px auto 0;
    padding: 0;
    
    display: block;
    white-space: pre-wrap;

    color: var(--texto);
  }
  

  /* Hora y Fecha */
  .BadgeLeerPost-fecha {
    font-family: 'Source Sans 3';
    width: 68%;
    height: auto;    
    
    font-size: 17px;
    
    margin: 8px auto 50px;
    display: block;

    color: var(--fecha);
  }
  .BadgeLeerPost-fecha:hover {
    cursor: default;
  }
  
  /* Aquí van los estilos para escritorios grandes */
  @media screen and (min-width: 1560px){

    /* Texto */
    .BadgeLeerPost-texto {
      width: 56%;
      margin: 28px 16%;
      font-size: larger;
    }

    /* Hora y Fecha */
    .BadgeLeerPost-fecha {
      font-size: 20px;
      
      margin: 8px auto 50px;
    }

  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 900px){

    .BadgeLeerPost {
      min-height: 70vh;
    }

    /* Imagen de portada */
    .BadgeLeerPost-portada {
      height: 50vh;
      background: #f9fafc;
      filter: drop-shadow(0px 1px 1px #31313120);
    }


    /* Firma del Autor */
    .BadgeLeerPost-firma {
      width: 86%;
      margin-bottom: 8px;
    }

    /* Firma del Autor -- avatar */
    .BadgeLeerPost-firma-avatar {
      height: 48px;
      width: 48px;

      margin-right: 8px;
    }

    /* Firma del Autor -- nombre */
    .BadgeLeerPost-firma-nombre {
      font-size: 17px;
    }

    /* Firma del Autor -- verificado */
    .BadgeLeerPost-firma-verificado {
      height: 19px;
      width: 19px;

      margin-left: 4px;
    }


    /* Categoría */
    .BadgeLeerPost-categoria {
      width: fit-content;

      display: flex;
      justify-content: center;
      align-content: center;
      
      align-self: flex-start;

      margin: 16px 7% 0;
      padding: 4px 8px;

      border-radius: 16px;

      font-weight: bold;
      font-size: small;
      font-family: 'Nunito';
    }

    /* Texto */
    .BadgeLeerPost-texto {
      width: 86%;
      
      margin: 8px auto 16px;
      padding: 0;
      display: block;
    }

    /* Hora y Fecha */
    .BadgeLeerPost-fecha {
      width: 86%;
      
      margin: 20px auto;
      font-size: 15px;
    }


  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    .BadgeLeerPost {
      min-height: 70vh;
    }

    /* Firma del Autor */
    .BadgeLeerPost-firma {
      width: 100%;
      margin-bottom: 8px;

      padding-inline: 16px;
    }


    /* Categoría */
    .BadgeLeerPost-categoria {
      width: fit-content;

      display: flex;
      justify-content: center;
      align-content: center;
      
      align-self: flex-start;

      margin: 16px 16px 0;
      padding: 4px 8px;

      border-radius: 16px;

      font-weight: bold;
      font-size: small;
      font-family: 'Nunito';
    }

    /* Texto */
    .BadgeLeerPost-texto {
      width: 100%;
      
      margin: 8px auto 16px;
      padding-inline: 16px;
      display: block;
    }

    /* Hora y Fecha */
    .BadgeLeerPost-fecha {
      width: 100%;
      
      margin: 20px auto;
      padding-inline: 16px;
      font-size: 15px;
    }


  }
 