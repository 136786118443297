.TarjetaAutor {
    width: 90%;
    height: auto;

    max-width: 550px;
    
    margin: 20px auto 100px;

    overflow: hidden;
    
    flex-direction: column;
    display: flex;

    background: #ffffff;
    box-shadow: 1px 1px 5px #31313170;

    display: block;
    justify-content: center;
    align-items: center;

    flex: 1 1 400px;
  }

  .TarjetaAutor__badge {
    overflow: hidden;
    
    flex-direction: column;
    display: flex;

    display: block;
    justify-content: center;
    align-items: center;

    padding: 2rem;

    flex: 1 1 400px;
  }
  
  .TarjetaAutor__header {
    display: flex;
    justify-content: flex-end;
  }

  .TarjetaAutor__header-icon-verificado {
    width: 30px;
    height: 30px;
  
    margin: 16px 16px -1rem 0;
  }
  
  .TarjetaAutor__header-icon-verificado:hover {
    background-color: #c2ffd170;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .TarjetaAutor__avatar-fondo-portada{
    display: flex;
    flex-direction: column;
    background: #FFF5E6;

    width: auto;
    height: auto;
  }


  .TarjetaAutor__avatar {
    margin-top: -70px;
    margin-bottom: 1.5rem;
    
    object-fit: cover;
    -o-object-fit: cover;

    height: 56px;
    width: 56px;
    border-radius: 50%;
    
    margin: 2% 2% 2% 0;
  }

  .TarjetaAutor__section-name {    
    color: 	#4F5F98;
  }

  .TarjetaAutor__section-biografia {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    color: #756e6b;
    background: #fcfcff;
    border-radius: 10px;

    width: 90%;
  }

  .TarjetaAutor__list-icons{
    list-style: none;
    display: flex;
    gap: 12px;
  }

  .TarjetaAutor__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b618f;

    margin: 1rem 0 1rem 0;
  }
  
  .TarjetaAutor__paginaWeb {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b618f;
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    background-color: transparent;
    border: transparent;

    margin: 0 0 1rem 0;
  }

  .TarjetaAutor__paginaWeb:hover {
    color: #7289da;
    text-decoration: underline;
  }