.BadgeCrearMencion {
    background: #FFF;
    overflow: hidden;

    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    padding: 16px;
    margin: 0 auto 0;
}

  /* Imagen de portada */
  .BadgeCrearMencion-portada {
    height: 360px;
    width: 360px;
    background: #f9fafc;
    filter: drop-shadow(0px 1px 1px #31313110);
  }
  
  .BadgeCrearMencion-imagen-portada {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    
    opacity: 0.20;
    -moz-opacity: 0.20;
    filter:alpha(opacity=20);
  }

  /* Contenido de portada */  
  .BadgeCrearMencion-header-contenido-portada {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
    
    margin: -360px auto 0;
    padding: 0 5%;
  
    filter: contrast(100%);
  }

  .btn.btn-seleccionar-foto {
    width: auto;
    height: 180px;
    font-size: large;
  
    color: #31313190;
    background-color: transparent;
    border-color: transparent;
  
    margin: 90px 20px;
  }
  
  .btn.btn-seleccionar-foto:hover {
    color: #31313190;
    background-color: #ffffff20;
  }

  .btn.btn-mostrar-foto {
    width: min-content;
    height: 180px;
    font-size: large;
  
    color: #31313190;
    background-color: #ffffff20;
    border-color: transparent;
  
    margin: 90px 20px;
  }
  
  .btn.btn-mostrar-foto:hover {
    color: #31313190;
    background-color: transparent;
  }

  /* Título */
  .BadgeCrearMencion-titulo {
    width: 100%;
    overflow: hidden;

    margin: 20px 0 0;
    padding: 8px;

    border-style: solid;
    border-radius: 4px;
    border-width: 1px;

    font-size: 32px;
    font-family: 'Montserrat';
    font-weight: bolder;
    color: #45484e;
  }
  .BadgeCrearMencion-titulo:hover {
    background: #f1f2f590;
  }

  /* Subtítulo */
  .BadgeCrearMencion-subtitulo {
    width: 100%;
    overflow: hidden;
    
    margin-bottom: 16px;
    padding: 8px;

    border-style: solid;
    border-radius: 4px;
    border-width: 1px;

    font-size: 20px;
    font-family: 'Nunito';
    font-weight: bold;
    color: 	#7289da;
  }
  .BadgeCrearMencion-subtitulo:hover {
    background: #f1f2f590;
  }

  /* Cita */
  .BadgeCrearMencion-cita {
    width: 100%;
    overflow: hidden;
    
    margin-bottom: 16px;
    padding: 8px;

    border-style: solid;
    border-radius: 4px;
    border-width: 1px;

    font-size: 20px;
    font-family: 'Nunito';
    font-weight: normal;
    color: 	#45484e;
  }
  .BadgeCrearMencion-cita:hover {
    background: #f1f2f590;
  }

  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

  }
 
  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    .BadgeCrearMencion { 
      padding: 0px;
      margin: 0;
    }

    /* Imagen de portada */
  .BadgeCrearMencion-portada {
    height: 240px;
    width: 240px;
  }

    /* Contenido de portada */  
    .BadgeCrearMencion-header-contenido-portada {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: stretch;
      
      margin: -300px auto 0;
      padding: 0 5%;
    
      filter: contrast(100%);
    }


  }