.SidebarAdministrador-izquierdo {
  display: flex;
  flex-wrap: wrap;

  width: 250px;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  padding: 1rem 16px;

  background: #fbfbfb;
  filter: drop-shadow(1px -1px 1px #31313140);

  transform: translate(-250px);
  transition: 0.3s;
}

/* Aquí van los estilos para tabletas medianas */
@media screen and (max-width: 767px){

  .SidebarAdministrador-izquierdo {
    padding: 1rem 16px;
  }

}