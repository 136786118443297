.BotonThemeDark {
  z-index: 5;
  padding: 0;
  background-color: transparent;
  filter: drop-shadow(1px 1px 5px #31313130);
      
  min-width: min-content;
  min-height: min-content;
      
  max-height: 50px;
  max-width: 50px;
  
  position: fixed;
  left: 16px;
  bottom: 16px;
}

.BotonThemeDark-temaClaro {
  display: inline-flex;
  justify-content: center;
      
  cursor: pointer;
  min-width: 50px;
  min-height: 50px;
  

  border-width: 1px;
  border-style: solid;
  border-color: #56a8ff40;
  
  border-radius: 50%;
  background: #FFF7E0;
  padding: 12px;
  margin: 0;

  transition: 0.7s;
}
.BotonThemeDark-temaClaro:hover{
  align-items: center;
  border-color: #56a8ff70;

  border-style: ridge;
}

.BotonThemeDark-temaOscuro {
  display: inline-flex;
  justify-content: center;
      
  cursor: pointer;
  min-width: 50px;
  min-height: 50px;
  

  border-width: 1px;
  border-style: solid;
  border-color: #56a8ff40;
  
  border-radius: 50%;
  background: #0b2e54;
  padding: 12px;
  margin: 0;

  transition: 0.7s;
}
.BotonThemeDark-temaOscuro:hover{
  align-items: center;
  border-color: #56a8ff70;

  border-style: ridge;
}

/* Para ocultar el botón */
.BotonThemeDark-oculto {
  z-index: 5;
  padding: 0;
  background-color: transparent;
            
  height: 0px;
  width: 0px;
}

/* Aquí van los estilos para tabletas medianas */
@media screen and (max-width: 767px){

  .BotonThemeDark {
    right: 16px;
    /* bottom: 81px; */
    bottom: 16px;

    max-height: 40px;
    max-width: 40px;
  }
  .BotonThemeDark:hover {
    filter: drop-shadow(1px 1px 5px #56a8ff70);
  }

  .BotonThemeDark-temaClaro {
    min-width: 40px;
    min-height: 40px;
  }
  
  .BotonThemeDark-temaOscuro {
    min-width: 40px;
    min-height: 40px;
  }

}