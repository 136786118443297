:root {

  /*---------- Tema de día ----------*/
  --light-background-component-main: #e7e7e750;

  /* Texto */
  --light-logo: #5b5553f0;
  --light-titulo-main: #ff895e7b;
  --light-texto: #5b5552d7;

  /* Botones */
  --light-link-menu: #313131;
  --light-link-menu-hover: #fff;

  --light-icon: 0%;
  --light-icon-hover: 100%;
  --light-url: 0%;

  --light-link-bottom: #191919;
  --light-link-bottom-hover: #fff;

  /*---------- Tema de noche ----------*/
  --dark-background-component-main: #272727;

  /* Texto */
  --dark-logo: #ccc8c7;
  --dark-titulo-main: #ff885e;
  --dark-texto: #dedddc;

  /* Botones */
  --dark-link-menu: #fff;
  --dark-link-menu-hover: #000;
  --dark-background-link-menu-hover: #fff;

  --dark-icon: 100%;
  --dark-icon-hover: 0%;
  --dark-url: 0%;

  --dark-link-bottom: #fff;
  --dark-link-bottom-hover: #000;
}

/* ------------------------------ TEMA LIGHT --------------------------- */

[theme="light"] {

  /* Padre */
  --background-component-main: var(--light-background-component-main);

  /* Texto */
  --logo: var(--light-logo);
  --titulo-main: var(--light-titulo-main);
  --texto: var(--light-texto);

  /* Botones */
  --link-menu: var(--light-link-menu);
  --link-menu-hover: var(--light-link-menu-hover);

  --icon: var(--light-icon);
  --icon-hover: var(--light-icon-hover);
  --url: var(--light-url);

  --link-bottom: var(--light-link-bottom);
  --link-bottom-hover: var(--light-link-bottom-hover);
}

/* ------------------------------ TEMA DARK ---------------------------- */

[theme="dark"] {

  /* Padre */
  --background-component-main: var(--dark-background-component-main);

  /* Texto */
  --logo: var(--dark-logo);
  --titulo-main: var(--dark-titulo-main);
  --texto: var(--dark-texto);

  /* Botones */
  --link-menu: var(--dark-link-menu);
  --link-menu-hover: var(--dark-link-menu-hover);

  --icon: var(--dark-icon);
  --icon-hover: var(--dark-icon-hover);
  --url: var(--dark-url);

  --link-bottom: var(--dark-link-bottom);
  --link-bottom-hover: var(--dark-link-bottom-hover);
}

/* ------------------------------ */

.BadgeMain__portada {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: stretch;

  padding: 5% 7%;
  background: var(--background-component-main);
}

  /* Título */
  .BadgeMain__titulo {
    display: block;
    width: 100%;

    margin: 17% 0 0;

    font-size: 300%;
    font-weight: bold;
    letter-spacing: 0px;
    
    font-family: 'Montserrat';
    color: var(--titulo-main);
  }

  /* Subtítulo */
  .BadgeMain__subtitulo {
    color:#006861;

    animation: colorAnimation 8s linear alternate;
    animation-iteration-count: infinite;
    
    display: inline-block;
    font-family: 'Nunito';
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin-top: 1rem;
    margin-left: 8px;
  }

  /* Descripción */
  .contenedor-descripcion {
    animation: colorAnimation 5s linear alternate;
    animation-iteration-count: infinite;
    
    display: inline-block;
    font-family: 'Nunito';
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin-top: 1rem;
    margin-left: 8px;
    color:#ff885e;
  }
  .contenedor-descripcion span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    animation: colorAnimation 5s linear alternate;
  }

  /* Animación de la descripción */
  @keyframes textColor {
    0% { color:#f04b4b; }
    25% { color:#24ee96; }
    50% { color:#7289da; }
    75% { color:#CD921E; }
    100% { color:#f04b4b; }
  }
  
  @keyframes colorAnimation {
    from { color: #7ccec3; }
    to { color: #006861; }
  }

  /* Imagen */
  .BadgeMain__imagen-media-pantalla {
    max-height: 350px;
    max-width: 40vh;
  
    object-fit: cover;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -150px auto 0;
    
    border-radius: 35px;
    border-width: 4px;
    border-color: #31313120;
    border-style: solid;
    padding: 0;
  }
  .BadgeMain__imagen-media-pantalla:hover {
    filter: contrast(1.05) blur(0.2px);
    cursor: context-menu;
  }

  /* Enlaces -- Redes sociales y página web */
  .BadgeMain__enlaces {
    display: flex;    
    align-items: flex-end;

    margin-top: 20px;
  }

/* Aquí van los estilos para escritorio */

/* Aquí van los estilos para tabletas grandes */
@media screen and (max-width: 942px){

  .BadgeMain__portada {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;

    height: 100vh;
  }

  /* Padre */
  .BadgeMain__container {    
    width: 100%;
    height: 100%;
    padding: 0.5rem 0;
    margin: 0 0;
  }

  .BadgeMain__imagen-media-pantalla {
    display: flex;

    max-width: 30vh;
    margin: -30% auto;
  }

}

/* Aquí van los estilos para tabletas medianas */
@media screen and (max-width: 767px){

  /* Título */
  .BadgeMain__titulo {
    margin: 10% 0 0;

    font-size: 300%;
    font-weight: bold;
  }

  /* Subtítulo */
  .BadgeMain__titulo {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  /* Descripción */
  .contenedor-descripcion {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .BadgeMain__descripcion {
    font-size: 24px;
  }

  /* Imagen */
  .BadgeMain__imagen-media-pantalla {
    display: flex;

    max-width: 30vh;
    margin: -35% auto;
  }
  
}

/* Aquí van los estilos para smartphones */
@media screen and (max-width: 479px){

  .BadgeMain__portada {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;

    height: 80vh;
  }

  /* Título */
  .BadgeMain__titulo {
    width: -moz-fit-content;
    width: fit-content;
    margin: 10% 0 0;
    padding: 0;

    font-size: 40px;
    font-weight: bold;
  }

  /* Subtítulo */
  .BadgeMain__subtitulo {
    font-size: 18px;
  }

  /* Descripción */
  .BadgeMain__descripcion {
    font-size: 18px;
  }

  /* Imagen */
  .BadgeMain__imagen-media-pantalla {
    display: flex;
    border-radius: 50%;

    max-width: 180px;
    margin: -60% auto;
  }

}