.BadgeComentarios {
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    overflow: hidden;

    width: 100%;
    height: 100%;
    max-width: 300px;

    flex: 1 1 300px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 35px 35px 0;
  }
  
  .BadgeComentarios__header {
    width: 100%;
    height: 50px;
    background: #e7e7e740;
    display: flex;
    justify-content: space-between;
    
  }

  .BadgeComentarios__header-icon {
    border-radius: 20%;
    width: 50px;
    height: 50px;

    align-items: center;
  }
  
  .BadgeComentarios__avatar-fondo-portada{
    display: flex;
    flex-direction: column;
    background: #FFF5E6;

    width: auto;
    height: auto;
  }


  .BadgeComentarios__avatar {

    margin-top: -25px;
    

    object-fit: cover;
    width: 50px;
    height: 50px;
  }

  .BadgeComentarios__section-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    color: #415164;
  }

  .BadgeComentarios__section-texto {
    width: 100%;
    min-height: 100px;

    display: inline;
    justify-content: center;
    align-items: center;
    padding: 16px;
    color: #4C453B;

    font-weight: bold;
    font-size: medium;
    font-family: 'Montserrat';
  }

  .BadgeComentarios__editar-texto {
    width: 100%;
    min-height: 180px;

    padding: 10px;

    border-color: #dedede;
    background-color: #fff0;

    color: #4C453B;
    font-family: 'Nunito', 'Poppins';
    font-size: medium;
    font-weight: bold;
  }

  .BadgeComentarios__editar-input-fecha {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #343434;
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    background-color: transparent;
    border: transparent;

    margin: 0 0 16px 0;
  }

  .BadgeComentarios__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1rem 0 0.2rem;
  }
  
  .BadgeComentarios__paginaWeb {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #7289da;
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    background-color: transparent;
    border: transparent;

    margin: 0 0 16px 0;
  }

  .BadgeComentarios__horaProgramado {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #7289da;
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    background-color: transparent;
    border: transparent;

    margin: 10px 0 0;
  }

  .BadgeComentarios__usuarioProgramado {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #7289da;
    font-weight: bold;
    font-size: small;
    font-style: normal;
    text-decoration: none;

    background-color: transparent;
    border: transparent;

    margin: 0 0 10px;

    cursor: pointer;
  }

  .BadgeComentarios__fuente-bold {
    font-weight: bolder;
    font-size: medium;
    font-family: 'Nunito', 'sans-serif';
    color: #313131;
    margin: 0 0 5px;

    cursor: pointer;
  }

  .BadgeComentarios__fuente-light {
    font-weight: normal;
    font-size: medium;
    font-family: 'Nunito', 'sans-serif';
    color: #fc3468;
    margin: 0 0 5px;

    cursor: pointer;
  }