:root {

  /*---------- Tema de día ----------*/
  --light-background-component: #fff;
  --light-background-filter-component: drop-shadow(0px 1px 1px #31313140);

  --light-contrast-logo: 0%;

  --light-fuente: #1F2229;
  --light-fuente-hover: #272626;

  --light-fuente-bold: #ff885e;
  --light-fuente-bold-hover: #ff885e;

  --light-fuente-light: #56a8ff;
  --light-fuente-light-hover: #4d97e5;

  /* Texto */
  --light-texto: #1D1D1F;

  /* Botones */
  --light-link-menu: #313131;
  --light-link-menu-hover: #fff;
  --light-background-link-menu-hover: #7289da;

  --light-link-menu-principal: #fff;
  --light-link-menu-principal-hover: #313131;
  --light-background-link-menu-principal: #7289da;
  --light-background-link-menu-principal-hover: #a5b8fd70;

  --light-icon: 0%;
  --light-icon-hover: 100%;

  --light-link-bottom: gray;
  --light-link-bottom-hover: #fff;
  --light-background-link-bottom: #00000010;

  /*---------- Tema de noche ----------*/
  --dark-background-component: #000;
  --dark-background-filter-component: drop-shadow(0px 1px 1px #eaeaea40);

  --dark-contrast-logo: 100%;

  --dark-fuente: #f1f1f1;
  --dark-fuente-hover: #cce4ff;

  --dark-fuente-bold: #ff885e;
  --dark-fuente-bold-hover: #ff885e;

  --dark-fuente-light: #fff;
  --dark-fuente-light-hover: #cce4ff;

  /* Texto */
  --dark-texto: #fff;

  /* Botones */
  --dark-link-menu: #fff;
  --dark-link-menu-hover: #000;
  --dark-background-link-menu-hover: #fff;

  --dark-link-menu-principal: #fff;
  --dark-link-menu-principal-hover: #000;
  --dark-background-link-menu-principal: #7289da;
  --dark-background-link-menu-principal-hover: #fff;

  --dark-icon: 100%;
  --dark-icon-hover: 0%;

  --dark-link-bottom: #fff;
  --dark-link-bottom-hover: #000;
  --dark-background-link-bottom: #dad9d9;
}

/* ------------------------------ TEMA LIGHT --------------------------- */

[theme="light"] {

  /* Padre */
  --background-component: var(--light-background-component);
  --background-filter-component: var(--light-background-filter-component);

  --contrast-logo: var(--light-contrast-logo);

  --fuente: var(--light-fuente);
  --fuente-hover: var(--light-fuente-hover);

  --fuente-bold: var(--light-fuente-bold);
  --fuente-bold-hover: var(--light-fuente-bold-hover);
  --fuente-light: var(--light-fuente-light);
  --fuente-light-hover: var(--light-fuente-light-hover);

  /* Texto */
  --texto: var(--light-texto);

  /* Botones */
  --link-menu: var(--light-link-menu);
  --link-menu-hover: var(--light-link-menu-hover);
  --background-link-menu-hover: var(--light-background-link-menu-hover);

  --link-menu-principal: var(--light-link-menu-principal);
  --link-menu-principal-hover: var(--light-link-menu-principal-hover);
  --background-link-menu-principal: var(--light-background-link-menu-principal);
  --background-link-menu-principal-hover: var(--light-background-link-menu-principal-hover);

  --icon: var(--light-icon);
  --icon-hover: var(--light-icon-hover);

  --link-bottom: var(--light-link-bottom);
  --link-bottom-hover: var(--light-link-bottom-hover);
  --background-link-bottom: var(--light-background-link-bottom);
}

/* ------------------------------ TEMA DARK ---------------------------- */

[theme="dark"] {

  /* Padre */
  --background-component: var(--dark-background-component);
  --background-filter-component: var(--dark-background-filter-component);

  --contrast-logo: var(--dark-contrast-logo);
  --background-logo: var(--dark-background-logo);

  --fuente: var(--dark-fuente);
  --fuente-hover: var(--dark-fuente-hover);

  --fuente-bold: var(--dark-fuente-bold);
  --fuente-bold-hover: var(--dark-fuente-bold-hover);
  --fuente-light: var(--dark-fuente-light);
  --fuente-light-hover: var(--dark-fuente-light-hover);

  /* Texto */
  --texto: var(--dark-texto);

  /* Botones */
  --link-menu: var(--dark-link-menu);
  --link-menu-hover: var(--dark-link-menu-hover);
  --background-link-menu-hover: var(--dark-background-link-menu-hover);

  --link-menu-principal: var(--dark-link-menu-principal);
  --link-menu-principal-hover: var(--dark-link-menu-principal-hover);
  --background-link-menu-principal: var(--dark-background-link-menu-principal);
  --background-link-menu-principal-hover: var(--dark-background-link-menu-principal-hover);

  --icon: var(--dark-icon);
  --icon-hover: var(--dark-icon-hover);

  --link-bottom: var(--dark-link-bottom);
  --link-bottom-hover: var(--dark-link-bottom-hover);
  --background-link-bottom: var(--dark-background-link-bottom);
}

/* ------------------------------ */

.Navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    width: 100%;
    height: 70px;
    padding: 0 16px;
    text-decoration: none;

    z-index: 4;
    
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    background: var(--background-component);
    filter: var(--background-filter-component);
  }

  /* Fondo gris (para menú de sidebar) */
  .overlay {
    width: 100%;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  .overlay.open {
    opacity: 1;
    visibility: visible;
  }

  .overlay-izquierdo {
    width: 100%;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  .overlay-izquierdo.open {
    opacity: 1;
    visibility: visible;
  }

  /* Caja del Menú (para menú de sidebar) */
  .modal-derecho {
    width: 0;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    right: 0;

    background-color: var(--background-component);
    transform: translate(0);
    transition: 0.3s;
  }
  .modal-derecho.open {
    transform: translate(-250px);
  }

  .modal-izquierdo {
    width: 0;
    height: 100%;
    z-index: 8;

    position: fixed;
    top: 0;
    left: 0;

    background-color: var(--background-component);
    transform: translate(0);
    transition: 0.3s;
  }
  .modal-izquierdo.open {
    transform: translate(250px);
  }

  /* Caja de íconos */
  .lista-iconos {
    display: flex;
    align-content: center;
    align-items: center;
  }
  
  /* Íconos */
  .navbar-icon {
    width: 40px;
    height: 40px;

    display: grid;
    place-items: center;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    margin: 0 8px;
    padding: 7px;

    filter: invert(var(--icon));
  }
  .navbar-icon:hover {
    opacity: 1;
    background: #00000026;

    filter: invert(var(--icon));
  }

  /* Mantiene oculto el ícono de menú */
  .navbar-icon-oculto {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  /* Imagen de usuario en formato de un icono */
  div.navbar-icon-imagen {
    width: 40px;
    height: 40px;
    cursor: pointer;

    display: inline-block;
    position: relative;
    overflow: hidden;
    
    background: #E4E6EB;
    border: none;
    
    border-radius: 50%;
    opacity: 0.75;
    transition: 0.3s;
    margin: 0 8px;
    padding: 7px;
  }
  div.navbar-icon-imagen:hover {
    opacity: 1;
    background: #00000026;
  }

  div.navbar-icon-imagen img {
    width: 43px;
    height: 43px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Logo de la página */
  .Navbar__brand {
    display: flex;
    align-items: center;
    text-decoration: none;

    padding: 0 10px;
  }
  .Navbar__brand:hover {
    background-color: #a5b8fd20;
    border-radius: 10px;
    
    font-style: 'text';
    text-decoration: none !important;        
  }

  .Navbar__brand-oculto {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .Navbar__brand-oculto:hover {
    background-color: #a5b8fd20;
    border-radius: 10px;
    
    font-style: 'text';
    text-decoration: none !important;        
  }

  /* Nombre de la página */
  .Navbar__fuente {
    font-weight: bold;
    font-size: 28px;
    font-family: 'Montserrat';
    padding: 0.2rem 0 0;
    
    display: inline-flex;
    place-items: center;

    color: var(--fuente);
  }
  .Navbar__fuente:hover {
    color: var(--fuente-hover);
  }
  .Navbar__fuente-bold {
    font-weight: bolder;
    font-size: x-large;
    font-family: 'Segoe UI', 'Nunito';
    cursor: pointer;

    color: var(--fuente-bold);
  }
  .Navbar__fuente-light {
    font-weight: bold;
    font-size: 28px;
    font-family: 'Montserrat';
    padding: 0.2rem 0 0;
    
    display: inline-flex;
    place-items: center;

    color: var(--fuente-light);
  }
  .Navbar__fuente-light:hover {
    color: var(--fuente-light-hover);
  }
  
  /* Logo de la página */
  .Navbar__brand-logo {
    margin: 0 5px 0 0;
    width: 40px;
    height: 40px;
    object-fit: contain;

    filter: invert(var(--contrast-logo));
  }

  /* Texto: Menú */
  .texto-menu{
    text-decoration: none;
    align-items: center;
    font-size: medium;
    font-family: 'Montserrat';
    color: #313131;
  }
  .texto-menu:hover{
    text-decoration: none;
    align-items: center;
    color: #fd1205;
  }

  /* Link predeterminado de la página */
  .link-menu{
    text-decoration: none;
    font-weight: bolder;
    font-size: medium;
    font-family: 'Montserrat';

    margin: 1rem 8px;
    padding: 0.5rem 1rem;

    color: var(--link-menu);
  }
  .link-menu:hover{
    border-radius: 10px;

    color: var(--link-menu-hover);
    background: var(--background-link-menu-hover);
  }

  /* Link principal de la página */
  .link-menu-principal{
    text-decoration: none;
    font-weight: bolder;
    font-size: medium;
    font-family: 'Montserrat';
    color: #fff;

    margin: 1rem 8px;
    padding: 0.5rem 1rem;

    border-radius: 10px;
    background: #7289da;

    color: var(--link-menu-principal);
    background: var(--background-link-menu-principal);
  }
  .link-menu-principal:hover{
    color: #313131;
    background: #a5b8fd70;

    color: var(--link-menu-principal-hover);
    background: var(--background-link-menu-principal-hover);
  }




  /* Aquí van los estilos para tabletas medianas */
  @media screen and (max-width: 767px){

    .Navbar {
      min-height: 50px;
      padding: 0.2rem 1rem;
    }

    /* Se muestra el ícono */
    .navbar-icon-oculto {
      width: 40px;
      height: 40px;
      visibility: visible;

      display: grid;
      place-items: center;
      
      background: #E4E6EB;
      border: none;
      
      border-radius: 50%;
      opacity: 0.75;
      transition: 0.3s;
      margin: 0 8px;
      padding: 7px;
    }
    .navbar-icon-oculto:hover {
      opacity: 1;
      background: #00000026;
    }

    .Navbar__brand-oculto {
      display: none;
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    /* Se ocultan los botones del menú */
    .link-menu {
      display: none;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .link-menu-principal {
      visibility: hidden;
      width: 0;
      height: 0;
    }

  }

  /* Aquí van los estilos para smartphones */
  @media screen and (max-width: 479px){

    .Navbar {
      max-height: 60px;
      padding: 0.2rem 0.5rem;
    }

  }